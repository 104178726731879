import React, { useState } from 'react';

const ProductForm = ({ onSubmit }) => {
    const [sku, setSku] = useState('95223-3203');

    const handleSubmit = (e) => {
        e.preventDefault();
        //trim empty spaces
        onSubmit(sku.trim());
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Product SKU:
                <input type="text" value={sku} onChange={(e) => setSku(e.target.value)} />
            </label>
            <button type="submit">Generate Product Page</button>
        </form>
    );
};

export default ProductForm;
