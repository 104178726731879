import React, { useState } from 'react';
import ProductForm from './ProductForm';
import ProductPage from './ProductPage';
import './App.css';

function App() {
  const [selectedSku, setSelectedSku] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleFormSubmit = (sku) => {
        // Handle form submission logic here
        console.log('Form submitted with SKU:', sku);
        setIsFormSubmitted(true);
        setSelectedSku(sku);
    };
    return (
        <div className="a4-preview product-page" style={{display:"flex",flexDirection:"column",alignItems:"center", height:"297mm",width:"210mm",padding:"20pt"}}>
            {!isFormSubmitted ? (
                <ProductForm onSubmit={handleFormSubmit} />
            ) : (
                <ProductPage sku={selectedSku}/>
            )}
        </div>
    );

}

export default App;
