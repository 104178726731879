import React, {useEffect, useState} from 'react';
import axios from 'axios';
import QRCode from 'react-qr-code';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography} from '@mui/material';
import Barcode from "react-barcode";
import { Helmet } from 'react-helmet';

const ProductPage = ({sku}) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [productData, setProductData] = useState(null);
    const [attributeLabels, setAttributeLabels] = useState({});
    const [selectedOptionLabels, setSelectedOptionLabels] = useState({});
    const [basePrice, setBasePrice] = useState(null);
    const [finalPrice, setFinalPrice] = useState(null);
    const [ean, setEan] = useState(null);
    const [pageTitle, setPageTitle] = useState('')
    const brandImages =[
        'cross',
        'specialized',
        'thule',
        'tsg'
    ]
    const images = [
        "butuc",
        "butuc_spate",
        "butuc_fata",
        "frane",
        "jante",
        "manson",
        "pinioane",
        "schimbator",
        "suspensie_cadru",
        "cadru",
        "furca",
        "lant",
        "pedale",
        "pipa",
        "schimbator_foi",
        "schimbator_pinioane",
        "tija",
        "cauciucuri",
        "ghidon",
        "manete_frana",
        "transmisie",
        "sa",
        "motor",
        "baterie",
    ];



    const getSpecialPrice = async (data) => {
        const specialPriceAttribute = data.custom_attributes.find(attr => attr.attribute_code === 'special_price');
        const specialFromDateAttribute = data.custom_attributes.find(attr => attr.attribute_code === 'special_from_date');
        const specialToDateAttribute = data.custom_attributes.find(attr => attr.attribute_code === 'special_to_date');

        if (specialPriceAttribute && specialFromDateAttribute && specialToDateAttribute) {
            const specialPrice = parseFloat(specialPriceAttribute.value);
            const specialFromDate = Date.parse(specialFromDateAttribute.value);
            const specialToDate = Date.parse(specialToDateAttribute.value);

            if (isNaN(specialPrice) || isNaN(specialFromDate) || isNaN(specialToDate)) {
                return null;
            }

            const currentDate = Date.now();

            if (currentDate >= specialFromDate && currentDate <= specialToDate) {
                return specialPrice;

            }
        }

        return null;
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const productResponse = await axios.get(`https://crossbike.ro/rest/V1/products/${sku}`);
                const product = productResponse.data;
                console.log(product);

                setProductData(product);
                if (product.type_id === 'configurable') {
                    const basePriceResponse = await axios.get(`https://crossbike.ro/rest/V1/configurable-products/${sku}/children`);
                    const childProduct = basePriceResponse.data[0];
                    if (childProduct) {
                        setBasePrice(childProduct.price);
                        const specialPrice = await getSpecialPrice(childProduct);
                        setFinalPrice(specialPrice !== null ? specialPrice : childProduct.price);
                    }
                } else {
                    setBasePrice(product.price);
                    const specialPrice = await getSpecialPrice(product);
                    setFinalPrice(specialPrice !== null ? specialPrice : product.price);
                }
                const attributeOrder = [
                    'Cadru',
                    'Jante',
                    'Furca',
                    'Suspensie Cadru',
                    'Transmisie',
                    'Motor',
                    'Baterie',
                    // Add more attribute codes in the desired order
                    "Frane",
                    "Cauciucuri",
                    "Butuc",
                    "Bucuc fata",
                    "Sa",
                    "Lant",
                    "Pipa",
                    "Pinioane",
                    "Schimbator foi",
                    "Butuc spate",
                ];
                const allowedAttributeLabels = [
                    "Cadru",
                    "Jante",
                    "Furca",
                    "Suspensie cadru",
                    "Schimbator foi",
                    "Schimbator pinioane",
                    "Schimbator",
                    // "Cauciucuri",
                    // "Frane",
                    // "Butuc",
                    // "Bucuc fata",
                    "Transmisie",
                    // "Sa",
                    // "Lant",
                    // "Pipa",
                    // "Cauciucuri",
                    // "Pinioane",
                    // "Butuc spate",
                    "Motor",
                    "Baterie",
                ];

                if (product.custom_attributes.find(attr => attr.attribute_code === 'ean')) {
                    setEan(product.custom_attributes.find(attr => attr.attribute_code === 'ean').value);
                }
                setPageTitle(product.name);
                const attributePromises = product.custom_attributes.map(async (attr) => {
                    const attributeUrl = `https://crossbike.ro/rest/V1/products/attributes/${attr.attribute_code}`;
                    const attributeResponse = await axios.get(attributeUrl);
                    const attributeInfo = attributeResponse.data;
                    const attributeCode = attributeInfo.default_frontend_label || attributeInfo.attribute_code;
                    const frontendLabel = attributeInfo.frontend_labels[0]?.label || attributeCode;

                    if (allowedAttributeLabels.includes(frontendLabel)) {
                        setAttributeLabels(prevLabels => ({...prevLabels, [attributeCode]: frontendLabel}));
                    }
                    if (attributeInfo.options) {
                        const selectedOption = attributeInfo.options.find(option => option.value == attr.value);
                        if (selectedOption) {
                            setSelectedOptionLabels(prevLabels => ({...prevLabels, [attributeCode]: selectedOption.label}));
                        } else {
                            setSelectedOptionLabels(prevLabels => ({...prevLabels, [attributeCode]: attr.value}));
                        }
                    }
                });

                //reorder the entries from the attributeLabels object base on the positions in the attributeOrder array
                const orderedAttributeLabels = {};
                attributeOrder.forEach(attributeCode => {
                    if (attributeLabels[attributeCode]) {
                        orderedAttributeLabels[attributeCode] = attributeLabels[attributeCode];
                    }
                });
                console.log(orderedAttributeLabels);
                setAttributeLabels(orderedAttributeLabels);

                await Promise.all(attributePromises);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        fetchData();
    }, [sku]);

    return (
        <div className="product-page" style={{height: "100%"}}>
            {productData && (
                <div className="product-details" style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <Helmet>
                        <title>{pageTitle}</title>
                    </Helmet>
                    <div className="wrap" style={{textAlign:"center"}}>
                        {brandImages.map(brand => (
                            productData.name.toLowerCase().includes(brand) ? (
                                <img
                                    key={brand}
                                    src={`/brands/${brand}.jpg`}
                                    alt={brand}
                                    style={{ width: '600px', height: 'auto',margin: "0 auto" }}
                                />
                            ) : null
                        ))}
                        <Typography variant="h1" sx={{margin: "20px", fontSize: "42px", textTransform: "uppercase", fontWeight: 700, textAlign: "center"}}>{productData.name}</Typography>
                        <Typography variant="h3" sx={{margin: "20px", fontSize: "18px", textTransform: "uppercase", fontWeight: 700, textAlign: "center"}}>{sku}</Typography>

                        <Typography variant="body1"></Typography>

                        <div style={{margin: '20px auto', display: "flex", justifyContent: (finalPrice < basePrice) ? "space-between" : "center", width: "250px"}}>
                            {basePrice && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        border: "3px solid",
                                        borderRadius: "15px",
                                        padding: "5px 10px",
                                        fontWeight: 700,
                                        fontSize: "24px",
                                        textDecoration: (finalPrice < basePrice) ? "line-through" : "none" // Apply text-decoration
                                    }}
                                >
                                    {basePrice} lei
                                </Typography>
                            )}
                            {finalPrice && basePrice !== finalPrice && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        border: "3px solid",
                                        borderRadius: "15px",
                                        padding: "5px 10px",
                                        fontWeight: 700,
                                        fontSize: "24px"
                                    }}
                                >
                                    {finalPrice} lei
                                </Typography>
                            )}
                        </div>
                    </div>
                    <TableContainer component={Paper} sx={{maxWidth: "700px", margin: "0 auto", minHeight: "400pt", boxShadow: "unset"}}>
                        <Table aria-label="Attribute Table">
                            <TableBody>
                                {Object.keys(attributeLabels).map(attributeCode => {
                                    const attributeLabelKey = attributeLabels[attributeCode]
                                        .toLowerCase()
                                        .replace(/\s+/g, '_'); // Convert to lowercase and replace spaces with underscores

                                    return (
                                        <TableRow key={attributeCode} className={attributeCode} >
                                            <TableCell sx={{border:"none",padding:0, fontWeight:500,width: '33%', textAlign:"center"}}>
                                                {images.includes(attributeLabelKey) ? (
                                                    <img
                                                        src={`/parts/${attributeLabelKey}.jpg`} // Replace with the correct image path
                                                        alt={attributeLabels[attributeCode]}
                                                        style={{width: '50px', height: '50px'}} // Adjust width and height as needed
                                                    />
                                                ) : attributeLabelKey}
                                            </TableCell>
                                            <TableCell sx={{border:"none",padding:0, fontWeight:700}}>{selectedOptionLabels[attributeCode] || '-'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{maxWidth: "700px", margin: "0 auto", boxShadow: "unset"}}>
                        <Table aria-label="Attribute Table">

                            <TableBody>
                                <TableRow sx={{maxHeight: "50mm"}}>
                                    <td style={{width: '33%'}}>
                                        {ean ? <Barcode value={ean}/> : null}
                                    </td>
                                    <td style={{width: '33%'}}><Typography variant="h3" sx={{
                                        margin: "20px",
                                        fontSize: "18px",
                                        textTransform: "uppercase",
                                        fontWeight: 700,
                                        textAlign: "center"
                                    }}>crossbike.ro</Typography></td>
                                    <td style={{width: '33%', textAlign: "center"}}><QRCode size={100}
                                                                                            value={`https://crossbike.ro/${productData.custom_attributes.find(attr => attr.attribute_code === 'url_key').value}`}/>
                                    </td>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>
            )}
        </div>
    );

};

export default ProductPage;
